import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { RichText } from 'prismic-reactjs'


const Header = ({data}) => {

  const doc = data.prismic.allMetadatas.edges.slice(0,1).pop().node;

  return (
  <header class="autogrid">
     <h1>
      <Link to="/" id="header-title">
          {RichText.asText(doc.titre_du_site)}
      </Link>
      </h1>
      <ul id="header-menu" class="unstyled">
        {doc.menu_header.map(section => (
             <li class="item-center"><Link to={section.lien}>{section.label}</Link></li>
          ))
        }
      </ul>
  </header>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default props => (
  <StaticQuery
    query={graphql`
    query {
      prismic {
        allMetadatas {
          edges {
            node {
              titre_du_site
              image_du_titre
              image_du_titreSharp {
                childImageSharp {
                  fixed(height: 25) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              gros_titre_header
              menu_header {
                label
                lien
              }
            }
          }
        }
      }
    }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
