import { StaticQuery, graphql } from "gatsby"
import React from "react"
import { RichText } from 'prismic-reactjs'


const Footer = ({data}) => {

  const doc = data.prismic.allMetadatas.edges.slice(0,1).pop().node;

  return (
  <footer>
      <div id="footer-map" dangerouslySetInnerHTML={{ __html: RichText.asText(doc.carte)}} />
      <div id="footer-content" class="content-center">
        {RichText.render(doc.gros_titre_footer)}
        <div class="grid-3 has-gutter-xl" >
          <div>
            {RichText.render(doc.label_address)}
            <ul class="unstyled">
              <li>{RichText.asText(doc.rue_de_la_maison)}</li>
              <li>{RichText.asText(doc.code_postal)}</li>
            </ul>
          </div>
          <div>
            {RichText.render(doc.label_contact)}
            {RichText.render(doc.responsable)}
            <ul class="unstyled">
              <li><a href={'mailto:' + RichText.asText(doc.email)}>{RichText.asText(doc.email)}</a></li>
              <li><a href={'tel:' + RichText.asText(doc.telephone)}>{RichText.asText(doc.telephone)}</a></li>
            </ul>
          </div>
          <div>
            {RichText.render(doc.label_oblats)}
            <ul class="unstyled">
            {doc.liens_oblats.map(section => (
                <li><a target="_blank" rel="noopener noreferrer" href={section.lien.url} >{section.label}</a></li>
              ))
            }
            </ul>
          </div>
        </div>
    
    </div>
  </footer>
)
}

export default props => (
  <StaticQuery
    query={graphql`
    query {
      prismic {
        allMetadatas {
          edges {
            node {
              carte
              code_postal
              gros_titre_footer
              email
              gros_titre_header
              responsable
              rue_de_la_maison
              telephone
              titre_du_site
              label_address
              label_oblats
              label_contact
              menu_header {
                label
                lien
              }
              liens_oblats {
                label
                lien {
                  __typename
                 ... on PRISMIC__ExternalLink{
                   url
                 }
               }
              }
            }
          }
        }
      }
    }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
