import React from "react"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = (props) => {
  return (
      <div class="image-with-text">
        {props.text ? 
           <><h2>{props.title}</h2>
           {props.text}</> :
           <h2 class="single">{props.title}</h2>
        }
        <Img fluid={props.img} />
      </div>
    )
}

export default Image
