/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'

const SEO = ({ allData, lang, meta, title }) => {
  const site = allData.site
  const prismicDoc = allData.prismic.allMetadatas.edges.slice(0,1).pop().node;
  const metaDescription = RichText.asText(prismicDoc.gros_titre_header)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${RichText.asText(prismicDoc.titre_du_site)}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default props => (
  <StaticQuery
    query={graphql`
    query {
      site {
        siteMetadata {
          author
        }
      },
      prismic {
        allMetadatas {
          edges {
            node {
              titre_du_site
              gros_titre_header
              gros_titre_footer
            }
          }
        }
      }
    }
    `}
    render={data => <SEO allData={data} {...props} />}
  />
)
